'use client';

import React, { Suspense } from 'react';
import NavBar from '@/components/marketing/NavBar';
import { RedirectToAuth0 } from '@/app/(auth-callbacks)/login/RedirectToAuth0';

export default function Page() {
    return (
        <Suspense>
            <>
                <NavBar />
                <RedirectToAuth0 />
            </>
        </Suspense>
    );
}
