'use client';
import React from 'react';
import { AuthButtonPopup } from '@/components/AuthButtons';
import { LinkButton } from '@/components/ui/button';
import { useAuth0 } from '@auth0/auth0-react';
import useAuth from '@/hooks/useAuth';
import { ProfileDropdownMenu } from '@/components/ProfileDropdownMenu';

export type NavBarAuthButtonsProps = {};

export function NavBarAuthButtons({}: NavBarAuthButtonsProps) {
    const { isAuthenticated, isLoading } = useAuth0();
    const { subscriber } = useAuth();
    const onboardingCompleted = (subscriber?.account_status ?? 0) > 0;
    return (
        <>
            {!isAuthenticated && !isLoading && (
                <div className={'flex items-center space-x-2'}>
                    <AuthButtonPopup
                        text="Log in"
                        mode="login"
                        size={'sm'}
                        minWidth={'xs'}
                        variant={'ghost'}
                        enableRedirect={true}
                        continueUrl={'/dashboard'}
                    />
                    {/*<LoginButton variant="ghost" size={'sm'} minWidth={'sm'} />*/}
                    <LinkButton href="/welcome" className="font-bold" size={'sm'} minWidth={'sm'}>
                        Sign Up
                    </LinkButton>
                </div>
            )}
            {isAuthenticated && onboardingCompleted && subscriber && (
                <>
                    <LinkButton href={'/refer-a-friend'} variant={'ghost'} className={'hidden sm:flex'}>
                        Refer a Friend
                    </LinkButton>
                    <LinkButton href={'/dashboard'} variant={'ghost'}>
                        Dashboard
                    </LinkButton>
                    <ProfileDropdownMenu />
                </>
            )}
            {isAuthenticated && !onboardingCompleted && subscriber && (
                <LinkButton href="/welcome" className="font-bold">
                    Continue Setup
                </LinkButton>
            )}
        </>
    );
}

NavBarAuthButtons.displayName = NavBarAuthButtons;
