'use client';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuthorizationParams } from '@/components/AuthButtons';
import LoadingMessage from '@/components/LoadingMessage';

export type RedirectToAuth0Props = {};

export function RedirectToAuth0({}: RedirectToAuth0Props) {
    const { loginWithRedirect, isLoading } = useAuth0();

    useEffect(() => {
        if (!isLoading) {
            const options = getAuthorizationParams({ mode: 'login' });
            loginWithRedirect({ authorizationParams: options });
        }
    }, [isLoading]);

    return (
        <div className={'flex items-center justify-center px-8 py-20'}>
            <LoadingMessage message={'Loading...'} />
        </div>
    );
}

RedirectToAuth0.displayName = 'RedirectToAuth0';
