'use client';
import { isDefined, isString } from '@/util/TypeGuards';
import { captureException } from '@sentry/nextjs';

export enum StorageKey {
    ONBOARDING_STATE = `prefare:onboarding_state`,
    ONBOARDING_STEP = 'preafre:onbboarding_step',
    AUTH_REDIRECT_PATH = 'prefare:auth_redirect_path',
    LOG_LEVEL = 'prefare:log.level',
    LOG_NETWORK_TRAFFIC = 'prefare:log.network-traffic',
    REFERRAL_CODE = 'prefare:referral_code',
    SHOW_MEAL_CREDIT_ELIGIBILITY = 'prefare:show_mc',
    PREFERRED_EXPERIENCE = 'prefare:experience',
    KNOWN_DEVICE = 'prefare.known_device',
}

export default class LocalStorageService {
    static get localStorage() {
        if (typeof window !== 'undefined') {
            return window.localStorage;
        } else {
            captureException(new Error('attempting to access local storage from a non-browser environment'), {
                level: 'warning',
            });
        }
        return undefined;
    }

    static setItem<T>(key: StorageKey, item: T) {
        const parsedValue = isString(item) ? item : JSON.stringify(item);
        LocalStorageService.localStorage?.setItem(key, parsedValue);
    }

    static removeItem(key: StorageKey) {
        LocalStorageService.localStorage?.removeItem(key);
    }

    static getItem<T = string>(key: StorageKey, fallback?: T): T | null {
        const value = LocalStorageService.localStorage?.getItem(key);
        if (!isDefined(value)) {
            return fallback ?? null;
        }
        try {
            return JSON.parse(value);
        } catch (error) {
            if (error instanceof SyntaxError) {
                return value as unknown as T;
            }
            return fallback ?? null;
        }
    }

    static clearAll() {
        Object.values(StorageKey).forEach((key) => {
            LocalStorageService.removeItem(key);
        });
    }
}
