import { User } from '@auth0/auth0-react';
import { AUTH0 } from '@/config/Config';

type AnyUser = (User | Record<string, unknown>) & { roles?: string[] };
export type Role = 'admin' | 'super-admin';

export function getRoles(user?: AnyUser | null): Role[] {
    if (!user) {
        return [];
    }

    return user[AUTH0.CLAIMS_DOMAIN]?.roles ?? [];
}

export function hasAdminRole(user?: AnyUser): boolean {
    return getRoles(user).includes('admin');
}

export function hasSuperAdminRole(user?: AnyUser): boolean {
    return getRoles(user).includes('super-admin');
}
